import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";

const BookingSummaryPopup = ({ visible, onClose, bookingData, bookingType }) => {
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("selectedCustomer"));
    setCustomerData(user);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <Modal
      className="booking-summary-model"
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <h2 className="booking-summary-title">{bookingType} SUMMARY</h2>
      <div className="booking-summary-container">
        <div className="booking-summary-column">
          <div className="booking-summary-label">Booking Id</div>
          <div className="booking-summary-label">Booking Date</div>
          <div className="booking-summary-label">Booking Time</div>
          <div className="booking-summary-label">No. of Guests</div>
          <div className="booking-summary-label">Table Number</div>
          <div className="booking-summary-label">Customer Name</div>
          <div className="booking-summary-label">Contact</div>
          <div className="booking-summary-label">Special Request</div>
        </div>
        <div className="booking-summary-column-2">
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
          <div className="booking-summary-colon">:</div>
        </div>
        <div className="booking-summary-column">
          <div className="booking-summary-value">
            {bookingData?.response.data.message.booking_detail.name}
          </div>
          <div className="booking-summary-value">
            {formatDate(bookingData?.selectedDate)}
          </div>
          <div className="booking-summary-value">
            {bookingData?.selectedTime}
          </div>
          <div className="booking-summary-value">{bookingData?.numGuests}</div>
          <div className="booking-summary-value">
            {bookingData?.response.data.message.table_no.map(item => item.serial_number).join(", ")}
          </div>
          <div className="booking-summary-value">
            {customerData?.customer_name || "N/A"}
          </div>
          <div className="booking-summary-value">
            {customerData?.mobile_no || "N/A"}
          </div>
          <div className="booking-summary-value">
            {bookingData?.specialRequest || "N/A"}
          </div>
        </div>
      </div>
      <div className="booking-summary-footer">
        <Button className="booking-summary-close" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default BookingSummaryPopup;
