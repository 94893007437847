import React, { useEffect, useState, useContext } from "react";
import { Table, Tabs, Button, Spin ,Modal} from "antd";
import Layout from "../components/Layout";
import {
  cancelBooking,
  checkoutBooking,
  fetchCustomers,
  fetchPastBookingData,
  fetchUpcomingBookingData,
  checkInBooking,
  fetchPresentBookingData,
} from "../modules/LandingPage";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../common/CartContext";
const { TabPane } = Tabs;

const Booking = () => {
  const { setSelectedTab,cartItems } = useContext(CartContext);
  const [activeTab, setActiveTab] = useState("present");
  const [presentBooking, setPresentBooking] = useState([]);
  const [upcomingBooking, setUpcomingBooking] = useState([]);
  const [pastBooking, setPastBooking] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
  const [totalOrdersUpcoming, setTotalOrdersUpcoming] = useState(0);
  const [currentPagePast, setCurrentPagePast] = useState(1);
  const [totalOrdersPast, setTotalOrdersPast] = useState(0);
  const [currentPagePresent, setCurrentPagePresent] = useState(1);
  const [totalOrdersPresent, setTotalOrdersPresent] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(7);
  const navigate = useNavigate();
  const costCenter=localStorage.getItem('costCenter')
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const getUpcomingBookingData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetchUpcomingBookingData(costCenter, page);
      const processedData = response.data.message.booking_list.map(
        (booking) => {
          const table_nos = booking.booked_tables
            .map((table) => table.serial_number)
            .join(", ");
            const formattedDate = formatDate(booking.booking_date);
        const date_and_time = `${formattedDate} ${booking.booking_time}`;
          return {
            ...booking,
            table_nos,
            date_and_time,
          };
        }
      );
      setUpcomingBooking(processedData);
      setTotalOrdersUpcoming(response.data.message.number_of_orders);
      setItemPerPage(response.data.message.items_perpage);
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
      setLoading(false);
    }
  };

  const getPresentBookingData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetchPresentBookingData(costCenter, page);
      const processedData = response.data.message.booking_list.map(
        (booking) => {
          const table_nos = booking.booked_tables
            .map((table) => table.serial_number)
            .join(", ");
            const formattedDate = formatDate(booking.booking_date);
            const date_and_time = `${formattedDate} ${booking.booking_time}`;
          return {
            ...booking,
            table_nos,
            date_and_time,
          };
        }
      );
      setItemPerPage(response.data.message.items_perpage);
      setPresentBooking(processedData);
      setTotalOrdersPresent(response.data.message.number_of_orders);
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
      setLoading(false);
    }
  };

  const getPastBookingData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetchPastBookingData(costCenter, page);
      const processedData = response.data.message.booking_list.map(
        (booking) => {
          const table_nos = booking.booked_tables
            .map((table) => table.serial_number)
            .join(", ");
            const formattedDate = formatDate(booking.booking_date);
            const date_and_time = `${formattedDate} ${booking.booking_time}`;
          return {
            ...booking,
            table_nos,
            date_and_time,
          };
        }
      );
      setItemPerPage(response.data.message.items_perpage);
      setPastBooking(processedData);
      setTotalOrdersPast(response.data.message.number_of_orders);
    } catch (error) {
      console.log("Failed to load table data.", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChangeUpcoming = (page) => {
    setCurrentPageUpcoming(page);
    getUpcomingBookingData(page);
  };

  const handlePageChangePast = (page) => {
    setCurrentPagePast(page);
    getPastBookingData(page);
  };

  const handlePageChangePresent = (page) => {
    setCurrentPagePresent(page);
    getPresentBookingData(page);
  };

  const cancelBookinghandler = async (record) => {
    // Show confirmation modal
    Modal.confirm({
      title: "Confirm Cancel",
      content: `Are you sure you want to cancel the booking for ${record.customer_name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await cancelBooking(record.name);
          getUpcomingBookingData(currentPageUpcoming);
          getPastBookingData(currentPagePast);
          getPresentBookingData(currentPagePresent);
        } catch (error) {
          console.log("Failed to cancel booking.", error);
        }
      },
    });
  };

  const checkOutBookinghandler = async (record) => {
    try {
      await checkoutBooking(record.name, JSON.stringify(record.booked_tables));
      getUpcomingBookingData(currentPageUpcoming);
      getPastBookingData(currentPagePast);
      getPresentBookingData(currentPagePresent);
    } catch (error) {
      console.log("Failed to check out booking.", error);
    }
  };

  const checkInBookinghandler = async (record) => {
    if(cartItems.length>0||localStorage.getItem('booking_number')){
      Modal.error({
        title: "Attention!",
        content: "Please complete the current order, park the order, or reset the cart first.",
        className: "customer-error-modal",
      });
      return;
    }
 
    try {
      await checkInBooking(record.name, JSON.stringify(record.booked_tables));
      getUpcomingBookingData(currentPageUpcoming);
      getPastBookingData(currentPagePast);
      getPresentBookingData(currentPagePresent);
      localStorage.setItem("order_service_type", "Reservation");
      localStorage.setItem("booking_number", record.name);
      localStorage.setItem("table_no", JSON.stringify(record.booked_tables));
      const selectedCustomer = customers.find(
        (item) => item.name === record.customer
      );
      localStorage.setItem(
        "selectedCustomer",
        JSON.stringify(selectedCustomer)
      );
      setSelectedTab("Delivery");
      navigate("/main");
    } catch (error) {
      console.log("Failed to check in booking.", error);
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === "present") {
      setCurrentPagePresent(1);
    } else if (key === "upcoming") {
      setCurrentPageUpcoming(1);
    } else if (key === "past") {
      setCurrentPagePast(1);
    }
  };

  useEffect(() => {
    if (activeTab === "present") {
      getPresentBookingData(currentPagePresent);
    } else if (activeTab === "upcoming") {
      getUpcomingBookingData(currentPageUpcoming);
    } else if (activeTab === "past") {
      getPastBookingData(currentPagePast);
    }
  }, [activeTab, currentPagePresent, currentPageUpcoming, currentPagePast]);

  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const customerData = await fetchCustomers();
        setCustomers(customerData);
      } catch (err) {
        console.log("Failed to fetch customers.", err);
      }
    };
    loadCustomers();
  }, []);

  const presentColumns = [
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      className: "booking-column",
    },
    {
      title: "Contact No.",
      dataIndex: "customer_mobile_no",
      key: "customer_mobile_no",
      className: "booking-column",
    },
    {
      title: "Table No.",
      dataIndex: "table_nos",
      key: "table_nos",
      className: "booking-column",
    },
    {
      title: "No. of Guests",
      dataIndex: "no_of_guest",
      key: "no_of_guest",
      className: "booking-column",
    },
    {
      title: "Date & Time",
      dataIndex: "date_and_time",
      key: "date_and_time",
      className: "booking-column",
    },
    {
      title: "Status",
      dataIndex: "type",
      key: "type",
      className: "booking-column",
      render: (text, record) => {
        if (text === "") {
          return <span className="booking-status not-checked-in">Not Checked In</span>;
        }
        
        if (text === "Check IN") {
          return (
            <Button
              type="primary"
              className="btn-checked-in"
              onClick={() => {
                checkInBookinghandler(record);
              }}
            >
              Checked In
            </Button>
          );
        }
        
        if (text === "Check OUT") {
          return <span className="booking-status checked-out">Checked Out</span>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      className: "booking-column",
      render: (text, record) => {
        if (activeTab === "present" && record.type === "") {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="primary"
                 className="btn-check-in"
                onClick={() => {
                  checkInBookinghandler(record);
                }}
              
              >
                Check In
              </Button>
              <Button
                type="danger"
                className="btn-cancel"
                onClick={() => cancelBookinghandler(record)}
                
              >
                Cancel
              </Button>
            </div>
          );
        }
        if (activeTab === "present" && record.type === "Check IN") {
          return (
            <Button
              type="primary"
              style={{ marginRight: 8, padding: "0 10px" }}
              onClick={() => checkOutBookinghandler(record)}
            >
              Check Out
            </Button>
          );
        }
        return null;
      },
    },
  ];
  const upcomingColumns = [
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      className: "booking-column",
    },
    {
      title: "Contact No.",
      dataIndex: "customer_mobile_no",
      key: "customer_mobile_no",
      className: "booking-column",
    },
    {
      title: "Table No.",
      dataIndex: "table_nos",
      key: "table_nos",
      className: "booking-column",
    },
    {
      title: "No. of Guests",
      dataIndex: "no_of_guest",
      key: "no_of_guest",
      className: "booking-column",
    },
    {
      title: "Date & Time",
      dataIndex: "date_and_time",
      key: "date_and_time",
      className: "booking-column",
    },
    {
      title: "Status",
      dataIndex: "type",
      key: "type",
      className: "booking-column",
      render: (text) => {
        let displayText = "Not Checked In";
        let className = "not-checked-in";
    
        if (text === "Check IN") {
          displayText = "Checked In";
          className = "checked-in";
        } else if (text === "Check OUT") {
          displayText = "Checked Out";
          className = "checked-out";
        }
        return <span className={`booking-status ${className}`}>{displayText}</span>;    
      },
    },
    {
      title: "Action",
      key: "action",
      className: "booking-column",
      render: (text, record) => {
        if (activeTab === "upcoming" && record.type === "") {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="danger"
                 className="btn-cancel"
                onClick={() => cancelBookinghandler(record)}
              >
                Cancel
              </Button>
            </div>
          );
        }

        return null;
      },
    },
  ];

  const pastColumns = [
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      className: "booking-column",
    },
    {
      title: "Contact No.",
      dataIndex: "customer_mobile_no",
      key: "customer_mobile_no",
      className: "booking-column",
    },
    {
      title: "Table No.",
      dataIndex: "table_nos",
      key: "table_nos",
      className: "booking-column",
    },
    {
      title: "No. of Guests",
      dataIndex: "no_of_guest",
      key: "no_of_guest",
      className: "booking-column",
    },
    {
      title: "Date & Time",
      dataIndex: "date_and_time",
      key: "date_and_time",
      className: "booking-column",
    },
    {
      title: "Status",
      dataIndex: "type",
      key: "type",
      className: "booking-column",
      render: (text) => {
        let displayText = "Not Checked In";
        let className = "not-checked-in";
    
        if (text === "Check IN") {
          displayText = "Checked In";
          className = "checked-in";
        } else if (text === "Check OUT") {
          displayText = "Checked Out";
          className = "checked-out";
        }
        return <span className={`booking-status ${className}`}>{displayText}</span>;    
      },
    },
  ];

  return (
    <Layout>
      <div className="booking-page">
        {console.log("totalOrdersPresent", totalOrdersPresent)}
        <h1 className="booking-title">ORDERS</h1>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          className="booking-tabs"
        >
          <TabPane tab="Current Bookings" key="present">
            <Spin spinning={loading} tip="Loading...">
              <Table
                columns={presentColumns}
                dataSource={presentBooking}
                pagination={{
                  current: currentPagePresent,
                  total: totalOrdersPresent,
                  pageSize: itemPerPage,
                  onChange: handlePageChangePresent,
                  showSizeChanger: false,
                }}
              />
            </Spin>
          </TabPane>
          <TabPane tab="UPCOMING BOOKINGS" key="upcoming">
            <Spin spinning={loading} tip="Loading...">
              <Table
                columns={upcomingColumns}
                dataSource={upcomingBooking}
                pagination={{
                  current: currentPageUpcoming,
                  total: totalOrdersUpcoming,
                  pageSize: itemPerPage,
                  onChange: handlePageChangeUpcoming,
                  showSizeChanger: false,
                }}
              />
            </Spin>
          </TabPane>
          <TabPane tab="PAST BOOKINGS" key="past">
            <Spin spinning={loading} tip="Loading...">
              <Table
                columns={pastColumns}
                dataSource={pastBooking}
                pagination={{
                  current: currentPagePast,
                  total: totalOrdersPast,
                  pageSize: itemPerPage,
                  onChange: handlePageChangePast,
                  showSizeChanger: false,
                }}
              />
            </Spin>
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Booking;
