import React, { useEffect, useState ,useContext} from "react";
import { Modal, Button, Spin } from "antd";
import { CartContext } from "../common/CartContext";
import NoImgFood from "../assets/images/no-img.png"; // Placeholder image for items without image
import { useThemeSettings } from "./ThemeSettingContext";
function AttributesPopup({
  visible,
  onClose,
  item,
  addToCart
}) {
  const { addItemToCart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const themeSettings = useThemeSettings();
  useEffect(() => {
    const getAttributes = async () => {
      if (visible && item) {
        setLoading(true);
        try {
          const storedAttributes = localStorage.getItem(
            `attributes_${item.id}`
          );
          const fetchedAttributes = storedAttributes
            ? JSON.parse(storedAttributes)
            : [];
          setAttributes(fetchedAttributes);
        } catch (error) {
          console.error("Error fetching Attributes:", error);
          setAttributes([]);
        } finally {
          setLoading(false);
        }
      }
    };

    if (visible) {
      setValidationErrors([]);
      setSelectedAttributes([]);
      getAttributes();
    }
  }, [visible, item]);

  const calculateTotalPrice = () => {
    let totalPrice = parseFloat(item.product_price) || 0;

    // Calculate base item price with tax
    totalPrice = parseFloat(
      calculatePriceWithTax(item.product_price, item.tax)
    );
    attributes.forEach((attributeGroup, groupIndex) => {
      const groupSelections = selectedAttributes[groupIndex] || [];

      groupSelections.forEach((selectedOption) => {
        totalPrice +=
          parseFloat(
            calculatePriceWithTax(selectedOption.price, selectedOption.item_tax)
          ) || 0;
      });
    });

    return totalPrice.toFixed(2)*quantity;
  };
  const calculatePriceWithTax = (basePrice, taxInfo) => {
    let totalPrice = parseFloat(basePrice) || 0;

    taxInfo.forEach((tax) => {
      const taxPercentage = parseFloat(tax.custom_tax_percentage) || 0;
      const taxAmount = totalPrice * (taxPercentage / 100);
      totalPrice += taxAmount;
    });

    return totalPrice.toFixed(2); // Return the total price rounded to 2 decimal places
  };
  const totalPriceWithTax = calculatePriceWithTax(item.product_price, item.tax);
  const handleAddToCart = (event) => {
    event.stopPropagation();

    if (!validateSelections()) {
      return;
    }

    let itemToAdd = { ...item };
    let totalPrice = calculateTotalPrice();
     
    // Prepare the item to add to cart with selected options
    itemToAdd = {
      ...itemToAdd,
      total_price: totalPrice,
      selectedAttributes: selectedAttributes,
      quantity
      
    };

    localStorage.setItem(
      `selectedAttributes_${item.id}`,
      JSON.stringify(selectedAttributes)
    );
    
    addItemToCart(itemToAdd);
    onClose();
  };

  const truncateDescription = (description) => {
    const words = description.split(" ");
    return words.length > 40
      ? words.slice(0, 40).join(" ") + "..."
      : description;
  };

  const renderAllergens = () =>
    item.allergens?.[0]?.map((allergen, index) => (
      <img
        key={index}
        src={allergen.icon}
        alt={allergen.allergens}
        title={allergen.allergens}
        style={{ width: "24px", height: "24px", marginRight: "5px" }}
      />
    ));
  const toggleExpanded = () => setExpanded(!expanded);

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    if (quantity < item.stock[0].stock_qty) {
      setQuantity(quantity + 1);
    } else {
      Modal.warning({
        title: "Stock Limit Reached",
        content: `You cannot add more than ${item.stock[0].stock_qty} items to the cart.`,
      });
    }
  };

  const renderQuantityControls = () => (
    <div className="quantity-controls">
      <button onClick={handleDecreaseQuantity}>-</button>
      <span>{quantity}</span>
      <button onClick={handleIncreaseQuantity}>+</button>
    </div>
  );

  const handleOptionSelect = (groupIndex, option) => {
    const requiredSelections = parseSelectionRequirement(
      attributes[groupIndex]
    );
    const groupSelections = selectedAttributes[groupIndex] || [];
    const isMandatory = attributes[groupIndex].mandatory === 1;

    setSelectedAttributes((prevSelectedAttributes) => {
      const newSelectedAttributes = [...prevSelectedAttributes];

      if (!newSelectedAttributes[groupIndex]) {
        newSelectedAttributes[groupIndex] = [];
      }

      const optionExistsIndex = groupSelections.findIndex(
        (selectedOption) => selectedOption.item === option.item
      );

      if (optionExistsIndex !== -1) {
        newSelectedAttributes[groupIndex].splice(optionExistsIndex, 1);
      } else {
        if (requiredSelections > 0) {
          if (groupSelections.length < requiredSelections) {
            newSelectedAttributes[groupIndex] = [
              ...(newSelectedAttributes[groupIndex] || []),
              option,
            ];
          } else if (isMandatory) {
            newSelectedAttributes[groupIndex] = [
              ...newSelectedAttributes[groupIndex].slice(1),
              option,
            ];
          } else {
            newSelectedAttributes[groupIndex] = [
              ...newSelectedAttributes[groupIndex].slice(1),
              option,
            ];
          }
        } else {
          newSelectedAttributes[groupIndex] = [
            ...newSelectedAttributes[groupIndex],
            option,
          ];
        }
      }

      return newSelectedAttributes;
    });

    setValidationErrors((prevErrors) =>
      prevErrors.filter(
        (error) => !error.includes(`from ${attributes[groupIndex].name}`)
      )
    );
  };

  const parseSelectionRequirement = (attribute) => {
    const selectionDescription = attribute.description || "";

    if (attribute.mandatory === 1 || /Select \d+/.test(selectionDescription)) {
      if (selectionDescription === "Select 0") {
        return 0;
      }
      const match = selectionDescription.match(/Select (\d+)/);
      return match ? parseInt(match[1]) : 0;
    }

    return 0;
  };

  const validateSelections = () => {
    const errors = [];
  
    attributes.forEach((attributeGroup, groupIndex) => {
      const requiredSelections = parseSelectionRequirement(attributeGroup);
      const groupSelections = selectedAttributes[groupIndex] || [];
      const isMandatory = attributeGroup.mandatory === 1;
  
      if (isMandatory && groupSelections.length !== requiredSelections) {
        errors.push(
          `Please select exactly ${requiredSelections} item(s) from ${attributeGroup.name}.`
        );
      }
  
      if (!isMandatory && requiredSelections > 0) {
        if (groupSelections.length < requiredSelections && groupSelections.length > 0) {
          errors.push(
            `Please select exactly ${requiredSelections} item(s) from ${attributeGroup.name}.`
          );
        }
      }
    });
  
    setValidationErrors(errors);
    return errors.length === 0;
  };
  
  
  
  

  const numberToWord = (number) => {
    switch (number) {
      case 1:
        return "ONE";
      case 2:
        return "TWO";
      case 3:
        return "THREE";
      default:
        return `${number}`;
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      className="attribute-modal"
    >
      <div className="w-100 justify-content-center mb-3">
        <div className="d-flex align-items-stretch card-body">
          <div className="item-card-image">
            <img src={item?.image || NoImgFood} alt="Item" />
          </div>
          {item && (
            <div className="item-card-main">
              <div className="desc-left">
                <div className="card-text">
                  <div>
                    <h4 className="card-title">{item.name}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expanded
                          ? item.description
                          : truncateDescription(item.description),
                      }}
                      className={`item-description ${
                        expanded ? "expanded" : "collapsed"
                      }`}
                    ></p>
                    {item.description.split(" ").length > 20 && (
                      <span onClick={toggleExpanded} className="show-more">
                        {expanded ? "Show less" : "Show more"}
                      </span>
                    )}
                  </div>
                </div>
                <div className="item-card-price">
                  <p>
                  {themeSettings?.currency_symbol || "$"} <span>{totalPriceWithTax}</span>
                  </p>
                </div>
              </div>
              <div className="desc-bottom">
                <div className="allergens-icons">{renderAllergens()}</div>
                <div className="attr-qty-control">
                  {renderQuantityControls()}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="attribute-list scrollable-content">
          {loading ? (
            <div className="spin-loader">
              <Spin size="large" />
            </div>
          ) : (
            <>
              {attributes.map((attributeGroup, groupIndex) => {
                const requiredSelections =
                  parseSelectionRequirement(attributeGroup);
                const isValidationRequired = attributeGroup.mandatory === 1;
                const showAsterisk =
                  isValidationRequired && requiredSelections > 0;

                const selectionRequirement = parseSelectionRequirement(attributeGroup);  

                return (
                  <div key={groupIndex} className="attr-block">
                    <h4>
                      {attributeGroup.name}
                      {showAsterisk ? (
                        <>
                          <span className="asterisk">*</span>
                          {requiredSelections > 0 && (
                            <span className="select-text">
                              &nbsp;(
                              <span className="selection-requirement">
                                {` Select Any ${numberToWord(
                                  requiredSelections
                                )}`}
                              </span>
                              )
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                        {selectionRequirement > 0 && (
                          <span>
                            &nbsp;(
                            {` SELECT ANY ${numberToWord(selectionRequirement)}`})
                          </span>
                        )}
                        </>
                      )}
                    </h4>
                    <div className="attribute-cards">
                      {attributeGroup.options.map((option, optionIndex) => (
                        <div
                          className={`attribute-content ${
                            (selectedAttributes[groupIndex] || []).some(
                              (selectedOption) =>
                                selectedOption.item === option.item
                            )
                              ? "selected"
                              : ""
                          }`}
                          key={optionIndex}
                          onClick={() => handleOptionSelect(groupIndex, option)}
                        >
                          <div className="attr-desc">
                            <span className="attr-name">
                              {option.item_name}
                            </span>
                            <span className="attr-price">
                            {themeSettings?.currency_symbol || "$"}
                              {calculatePriceWithTax(
                                option.price,
                                option.item_tax
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        {validationErrors.length > 0 && (
          <div className="validation-errors">
            {validationErrors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))}
          </div>
        )}
        <div className="attr-popup-footer" >
          <div>
            <button type="button" className="attr-back-btn"  onClick={onClose}>
              Back
            </button>
          </div>
          <div className="attr-total">
            <span className="attr-price" id="popupbtn">
              ITEM TOTAL <span>  {themeSettings?.currency_symbol || "$"} {calculateTotalPrice()}</span>
            </span>
            <button className="attr-addToCart-btn" onClick={handleAddToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AttributesPopup;
