import React, { createContext, useState, useEffect } from "react";
import { Modal } from "antd";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState("Takeaway");
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  useEffect(() => {
    const prevSelectedTab = localStorage.getItem('selectedTab');
    if (prevSelectedTab) {
      setSelectedTab(prevSelectedTab);
    }
  }, []);

  const [originalPrices, setOriginalPrices] = useState(() => {
    const storedOriginalPrices = localStorage.getItem("originalPrices");
    return storedOriginalPrices ? JSON.parse(storedOriginalPrices) : {};
  });

  const [editedPrices, setEditedPrices] = useState({});
  const [lastAuthorizedPrices, setLastAuthorizedPrices] = useState({});
  const [storedPassword, setStoredPassword] = useState("");

  // Function to handle tab change
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    localStorage.setItem('selectedTab', tab); // Save the selected tab in localStorage
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("originalPrices", JSON.stringify(originalPrices));
  }, [originalPrices]);

  const addItemToCart = (item) => {
    const { id, selectedComboItems, selectedAttributes } = item;
  
    if (selectedTab === "Delivery") {
      if (!localStorage.getItem("booking_number")) {
        Modal.error({
          title: "Attention!",
          content: `Items can be added only after a 'Dine-in' booking has been created.`,
        });
        return;
      }
    }
  
    setCartItems((prevItems) => {
      // Check if there's a mismatch between current items' tab and the selected tab
      const hasDifferentTab = prevItems.some(
        (prevItem) => prevItem.selectedTab !== selectedTab
      );
  
      // If there is a different tab, clear the cart
      let updatedItems = prevItems;
      if (hasDifferentTab) {
        updatedItems = [];
        setOriginalPrices({}); // Optionally reset any other state related to cart
      }
  
      // Define the localStorage key
      const localStorageKey = `cartItem_${id}_${JSON.stringify(
        selectedComboItems
      )}_${JSON.stringify(selectedAttributes)}`;
  
      // Retrieve cart items from localStorage
      const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  
      const existingItemIndex = storedCartItems.findIndex((cartItem) => {
        return (
          cartItem.id === id &&
          JSON.stringify(cartItem.selectedComboItems) ===
            JSON.stringify(selectedComboItems) &&
          JSON.stringify(cartItem.selectedAttributes) ===
            JSON.stringify(selectedAttributes)
        );
      });
  
      const itemIndex = updatedItems.findIndex(
        (prevItem) =>
          prevItem.id === item.id &&
          prevItem.selectedTab === selectedTab &&
          JSON.stringify(prevItem.selectedComboItems) ===
            JSON.stringify(selectedComboItems) &&
          JSON.stringify(prevItem.selectedAttributes) ===
            JSON.stringify(selectedAttributes)
      );
  
      if (itemIndex >= 0 || existingItemIndex !== -1) {
        const indexToUse = itemIndex >= 0 ? itemIndex : existingItemIndex;
        const itemToUpdate = itemIndex >= 0 ? updatedItems : storedCartItems;
        
        // Update quantity if within stock limits
        if (
          itemToUpdate[indexToUse].stock[0].stock_qty >=
          itemToUpdate[indexToUse].quantity + item.quantity
        ) {
          itemToUpdate[indexToUse].quantity += item.quantity;
        } else {
          Modal.error({
            title: "Attention!",
            content: `Cannot add more Items. Available stock: ${itemToUpdate[indexToUse].stock[0].stock_qty}`,
          });
        }
  
        // Handle price updates if item is scanned
        if (item.isScanned && !itemToUpdate[indexToUse].price) {
          itemToUpdate[indexToUse].price = item.price;
        }
  
        // Update localStorage and state
        localStorage.setItem("cartItems", JSON.stringify(itemToUpdate));
        return [...itemToUpdate];
      } else {
        // Add a new item
        const newItem = {
          ...item,
          price: item.isScanned ? item.price : item.price || 0,
          selectedTab,
          quantity: item.quantity || 1,
          localStorageKey,
        };
  
        // Update prices if needed
        setOriginalPrices((prevPrices) => {
          if (!prevPrices[item.id]) {
            return { ...prevPrices, [item.id]: item.product_price };
          }
          return prevPrices;
        });
  
        // Add new item to localStorage and state
        storedCartItems.push(newItem);
        localStorage.setItem(localStorageKey, JSON.stringify(newItem));
        localStorage.setItem("cartItems", JSON.stringify(storedCartItems));
  
        return [...updatedItems, newItem];
      }
    });
  };
  
  
  // const removeItemFromCart = (itemId) => {
  //   setCartItems((prevItems) => {
  //     const updatedItems = prevItems.filter(
  //       (item) => !(item.id === itemId && item.selectedTab === selectedTab)
  //     );

  //     setOriginalPrices((prevPrices) => {
  //       const { [itemId]: _, ...rest } = prevPrices;
  //       return rest;
  //     });

  //     setEditedPrices((prevPrices) => {
  //       const { [itemId]: _, ...rest } = prevPrices;
  //       return rest;
  //     });

  //     setLastAuthorizedPrices((prevPrices) => {
  //       const { [itemId]: _, ...rest } = prevPrices;
  //       return rest;
  //     });

  //     return updatedItems;
  //   });
  // };
  const removeItemFromCart = (itemToRemove) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.filter((item) => {
        // Compare ID first
        if (item.id !== itemToRemove.id) {
          return true;
        }
  
        // Check if selectedTab matches
        if (item.selectedTab !== selectedTab) {
          return true;
        }
  
        // Compare selectedAttributes (assuming it's an array or object)
        const attributesMatch =
          JSON.stringify(item.selectedAttributes) ===
          JSON.stringify(itemToRemove.selectedAttributes);
  
        // Compare selectedComboItems (assuming it's an array or object)
        const comboItemsMatch =
          JSON.stringify(item.selectedComboItems) ===
          JSON.stringify(itemToRemove.selectedComboItems);
  
        // Only remove the item if all conditions match
        return !(attributesMatch && comboItemsMatch);
      });
  
      // Update related price state management
      const itemId = itemToRemove.id;
      setOriginalPrices((prevPrices) => {
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });
  
      setEditedPrices((prevPrices) => {
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });
  
      setLastAuthorizedPrices((prevPrices) => {
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });
  
      return updatedItems;
    });
  };
  
  const resetCart = () => {
    setCartItems((prevItems) => prevItems.filter((item) => item.selectedTab !== selectedTab));
    setOriginalPrices({});
    setEditedPrices({});
    setLastAuthorizedPrices({});
  };

  const completeOrder = () => {
    setCartItems((prevItems) => prevItems.filter((item) => item.selectedTab !== selectedTab));
    setOriginalPrices({});
    setEditedPrices({});
    setLastAuthorizedPrices({});
  };

  // Filter cart items based on selected tab
  const filteredCartItems = cartItems.filter(
    (item) => item.selectedTab === selectedTab
  );

  return (
    <CartContext.Provider
      value={{
        cartItems: filteredCartItems, // Pass filtered items
        addItemToCart,
        removeItemFromCart,
        resetCart,
        completeOrder,
        setCartItems,
        setEditedPrices,
        setLastAuthorizedPrices,
        setStoredPassword,
        selectedTab,
        setSelectedTab:handleTabChange,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
